@import "~core/client/ui/styles/utils/screen-height";
@import "~core/client/ui/styles/utils/screen-width";

.overlay {
  position: absolute;
  inset: 0;
  color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background: var(--color-black);

  &.error {
    background: var(--color-negative);
  }

  &.info {
    background: var(--color-main);
  }
}

.container {
  text-align: center;
  user-select: text;
  font-size: 28pt;
  padding: screenHeight(8%) screenWidth(8%);
}

.sign {
  font-size: 180%;
}

.title {
  font-size: 100%;
  text-transform: uppercase;
}

.message {
  font-size: 50%;
  letter-spacing: 1px;
}
